import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from "./view/Login";

const App = _ => (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Login />} />
        </Routes>
    </BrowserRouter>
)

export default App;