import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

const BtnCancelar = (props) => (
    <Button
        variant="danger"
        {...props}
        className={`rounded-pill ${props.className}`}
    >
        <FontAwesomeIcon
            className="me-2"
            icon={faXmark}
            fixedWidth
        />

        Cancelar
    </Button>
)

export default BtnCancelar;