import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

const BntConfirmar = (props) => (
    <Button
        variant="success"
        {...props}
        className={`rounded-pill ${props.className}`}
    >
        <FontAwesomeIcon
            className="me-2"
            icon={faCheck}
            fixedWidth
        />
        Confirmar
    </Button>
)

export default BntConfirmar;