import React, { useEffect, useState } from "react";

import { FormControl, InputGroup, Button, FormCheck, Spinner, Modal, FormSelect, FormLabel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faArrowRightToBracket, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import '../styles/Login.css';
import axios from "axios";
import Feedback from "react-bootstrap/esm/Feedback";
import BtnCancelar from "../components/templates/buttons/BtnCancelar";
import BntConfirmar from "../components/templates/buttons/BtnConfirmar";

const Login = _ => {

    /* SECTION Formulário de Login */
    /* SUBSECTION States */
    const [password, setPassword] = useState('');
    const [login, setLogin] = useState('');
    const [loading, setLoading] = useState(false); // Indicador se uma requisião ajax foi feita
    const [showPassword, setPasswordVisibility] = useState(false); // Visibilidade da senha
    const [clienteLogo] = useState('https://sistema.clinica.digital/lib/img/logo_insta_clinica_digital.png'); // Logo do ambiente 
    const [invalidComponents, setInvalidComponents] = useState(
        {
            login: { isInvalid: false, feedback: '' },
            password: { isInvalid: false, feedback: '' },
            dispositivo: { isInvalid: true, feedback: 'Este dispositivo não tem permissão de acesso.' }
        }
    ); // Validação dos formulários
    /* !SUBSECTION States */


    /* SUBSECTION Handlers */
    const handleChangePassword = e => {
        setInvalidComponents({ ...invalidComponents, [e.target.name]: { isInvalid: false, feedback: '' } });
        setPassword(e.target.value);
    };
    const handleChangeLogin = e => {
        setInvalidComponents({ ...invalidComponents, [e.target.name]: { isInvalid: false, feedback: '' } });
        setLogin(e.target.validity.valid ? e.target.value : login);
    };
    const handleClickButtonLogin = async _ => {
        const validation = {
            login: { isInvalid: login.length !== 11, feedback: 'O CPF deve possuir 11 algarismos.' },
            password: { isInvalid: password.length < 6, feedback: 'A Senha deve possuir ao menos 6 caracteres.' }
        };

        if (Object.values(validation).some(({ isInvalid }) => !!isInvalid))
            return setInvalidComponents({ ...invalidComponents, ...validation });

        try {

            const data = new FormData();
            data.append('idAcao', 'login');
            data.append('login', login);
            data.append('password', password);
            data.append('identidadeMobile ', identidadeMobile);

            setLoading(true);

            const response = await axios.post('/index_ajax.php', data);
            const { json } = response.data;

            if (json.validation)
                return setInvalidComponents({ ...invalidComponents, ...json.validation });

            const {
                alert,
                clientes = [],
                loginDireto = false,
                acessoMobile = false,
                dispositivoValido = false,
                horarioRestrito = true,
                idUsuario = null
            } = json;

            // Se aconteceu algum problema no servidor
            if (alert)
                return window.alert(alert.feedback)

            if (loginDireto) {
                if (!acessoMobile)
                    return window.alert('Este usuário não tem permissão para o acesso mobile. Vá para as configurações de usuário e se dê permissão.'); //TODO Adicionar dialog de aviso

                if (horarioRestrito)
                    return window.alert('Seus horários de acesso foram restringidos. Entre em contato com um usuário administrador.'); //TODO Adicionar dialog de aviso

                if (dispositivoValido)
                    return window.alert('Redirect para menu_principal!');
            }

            setValidadeDispositivo(dispositivoValido);
            setInvalidComponents({ ...invalidComponents, dispositivo: { isInvalid: !dispositivoValido, feedback: 'Este dispositivo não tem permissão de acesso.' } });
            setIdCliente(clientes[0].id);
            setIdUsuario(idUsuario);
            setClientesSelect(clientes);
        } catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    };
    const handleChangeRedirect = e => localStorage.setItem('redirect', e.target.checked); // Manter conectado 
    /* !SUBSECTION Handlers */
    /* !SECTION Formulário de Login */



    /* SECTION Dialog de Clientes */
    /* SUBSECTION States */
    const [clientesSelect, setClientesSelect] = useState([]); // Clientes que o usuário pode fazer login
    const [idCliente, setIdCliente] = useState(null); // Clientes que o usuário pode fazer login
    const [idUsuario, setIdUsuario] = useState(null); // Clientes que o usuário pode fazer login
    const [dispositivoValido, setValidadeDispositivo] = useState(false); // Se o dispositivo tem permissão de acesso
    const [identidadeMobile, setIdentidadeMobile] = useState(''); // Nome do dispositivo
    /* !SUBSECTION States */


    /* SUBSECTION Handlers */
    const handleChangeIdentidade = e => {
        setIdentidadeMobile(e.currentTarget.value);
        setInvalidComponents({ ...invalidComponents, [e.target.name]: { isInvalid: false, feedback: '' } });
    };
    const handleLogin = async _ => {
        const validation = {
            dispositivo: { isInvalid: identidadeMobile.length < 3, feedback: 'O nome do dispositivo deve possuir ao menos 3 caracteres.' }
        };

        if (Object.values(validation).some(({ isInvalid }) => !!isInvalid))
            return setInvalidComponents({ ...invalidComponents, ...validation });

        try {

            const data = new FormData();
            data.append('idAcao', 'loginEncerraLogAnterior');
            data.append('idUsuario', idUsuario);
            data.append('idCliente', idCliente);
            data.append('identidadeMobile ', identidadeMobile);

            setLoading(true);

            const response = await axios.post('/index_ajax.php', data);
            const { validation } = response.data.json;

            if (validation) {
                const { dispositivo, idCliente, idUsuario } = validation;
                if (idCliente || idUsuario)
                    return alert.window('Usuário Inválido! Realize o Login novamente.')
                return setInvalidComponents({ ...invalidComponents, dispositivo });
            }

        } catch (error) {
            console.log(error);
        }
        finally {
            localStorage.setItem('identidadeMobile', identidadeMobile);
            setLoading(false);
        }
    };
    /* !SUBSECTION Handlers */
    /* !SECTION Dialog de Clientes */


    const validateLogin = _ => {

    }


    useEffect(() => {

        /* const carregaPag = async _ => {
            const redirect = JSON.parse(localStorage.getItem('redirect'));

            const data = new FormData();
            data.append('idAcao', 'CarregaPag');
            data.append('redirect', !!redirect ? 'S' : 'N');

            const clientes = await axios.post('/index_ajax.php', data);
            console.log(clientes);
        }
        carregaPag(); */
        setIdentidadeMobile(localStorage.getItem('identidadeMobile') || '');
    }, [])


    return (
        <main className="d-flex flex-column">

            <div className="flex-grow-1 container d-flex flex-column justify-content-center text-center">

                <div className="m-5 client-logo rounded-circle align-self-center" style={{ backgroundImage: `url(${clienteLogo})` }}></div>

                <div className="my-2">
                    <FormControl
                        name="login"
                        className="rounded-pill"
                        size="lg"
                        inputMode='numeric'
                        placeholder="CPF"
                        pattern="[0-9]*"
                        autoFocus
                        value={login}
                        onChange={handleChangeLogin}
                        maxLength={11}
                        isInvalid={invalidComponents.login.isInvalid} />
                    <Feedback type="invalid" dangerouslySetInnerHTML={{ __html: invalidComponents.login.feedback }} />
                </div>

                <div className="my-2">
                    <InputGroup
                        hasValidation
                        size="lg"
                    >
                        <FormControl
                            name="password"
                            className='pillStart'
                            placeholder="Senha"
                            type={showPassword ? 'text' : 'password'}
                            onChange={handleChangePassword}
                            isInvalid={invalidComponents.password.isInvalid}
                        />
                        <Button
                            className="pillEnd"
                            checked={showPassword}
                            onClick={_ => setPasswordVisibility(!showPassword)}
                        >
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                fixedWidth
                            />
                        </Button>
                        <Feedback
                            type="invalid"
                            dangerouslySetInnerHTML={{ __html: invalidComponents.password.feedback }}
                        />
                    </InputGroup>

                    <FormCheck.Label>
                        <FormCheck.Input onChange={handleChangeRedirect} /> Manter Conectado?
                    </FormCheck.Label>
                </div>

                <div className="my-2">

                    {
                        loading ?
                            (
                                <Spinner animation="border" variant="success" />
                            )
                            :
                            (
                                <Button
                                    className={'rounded-pill'}
                                    variant="success"
                                    onClick={handleClickButtonLogin}>
                                    <FontAwesomeIcon
                                        icon={faArrowRightToBracket}
                                        fixedWidth
                                        className="me-2"
                                    />
                                    Acessar Desevolvimento
                                </Button>
                            )
                    }
                    <br />
                    <Button
                        variant="link"
                        size='sm'
                        className='mt-2'>
                        Não consegue acessar?
                    </Button>
                </div>
            </div>

            <footer
                className="flex-shrink-0 text-info d-flex justify-content-center align-items-center">
                <img
                    className="rounded me-2"
                    src="https://sistema.clinica.digital/lib/img/fav_techtrust.ico"
                    alt="Logo do Cliente" />
                <small>
                    Techtrust soluções em TI e processos
                </small>
            </footer>

            {/* Dialog de Selecionar Clientes */}
            <Modal
                show={!!clientesSelect.length}
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <div className={clientesSelect.length === 1 && 'd-nones'}>
                        <FormLabel>
                            Selecione o cliente que deseja acessar
                        </FormLabel>
                        <FormSelect
                            id="id_cliente"
                            className="rounded-pill"
                            onChange={e => setIdCliente(e.currentTarget.value)}
                            value={!!clientesSelect.length ? clientesSelect[0].id : undefined}
                            autoFocus >
                            {clientesSelect.map(({ id, nom_reduzido }) => (<option value={id} key={id}>{nom_reduzido}</option>))}
                        </FormSelect>
                    </div>

                    {
                        !dispositivoValido && (
                            <div className="mt-2 text-center">

                                <InputGroup hasValidation>
                                    <FormControl
                                        name="dispositivo"
                                        className="pillStart"
                                        placeholder="Nome do Dispositivo"
                                        isInvalid={invalidComponents.dispositivo.isInvalid}
                                        value={identidadeMobile}
                                        onChange={handleChangeIdentidade}
                                    maxLength={11}
                                    isInvalid={invalidComponents.login.isInvalid}
                                    />
                                    <Button
                                        className="pillEnd"
                                        checked={showPassword}
                                    // onClick={_ => setPasswordVisibility(!showPassword)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPaperPlane}
                                            fixedWidth
                                        />
                                    </Button>
                                    <Feedback type="invalid" dangerouslySetInnerHTML={{ __html: invalidComponents.dispositivo.feedback }} />
                                </InputGroup>
                            </div>
                        )
                    }


                </Modal.Body>
                <Modal.Footer>
                    <BtnCancelar onClick={_ => setClientesSelect([])} />
                    <BntConfirmar onClick={handleLogin} />
                </Modal.Footer>
            </Modal>
        </main>
    )
}

export default Login; 